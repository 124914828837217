/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, react/function-component-definition */
import { CircularProgress, Modal, Skeleton } from "@mui/material";
import { EmailRounded } from "@mui/icons-material";
import { differenceInMinutes } from "date-fns";
import React, { useState } from "react";
import tw, { styled } from "twin.macro";
import { bool, func, node, number, oneOf, oneOfType, string } from "prop-types";
import { Link } from "gatsby";

import { drawerWidthLg, drawerWidthSm, mq } from "../utils/global-consts";

const MyButton = styled.button(({ variant, disabled }) => [
  tw`flex justify-center items-center px-2 py-1 rounded font-semibold tracking-wide text-white transition duration-200 select-none`,
  variant === "primary" && tw`bg-prim-500 hover:bg-prim-600`,
  variant === "secondary" && tw`bg-sec-500 hover:bg-sec-600`,
  variant === "confirm" && tw`bg-green-500 hover:bg-green-600`,
  variant === "cancel" && tw`bg-red-600 hover:bg-red-700`,
  variant === "dismiss" && tw`bg-gray-300 text-gray-600 hover:bg-gray-400`,
  disabled &&
    tw`bg-gray-300 text-gray-600 hover:bg-gray-300 cursor-not-allowed`,
]);

export const JimButton = ({
  variant,
  children,
  onClick,
  disabled,
  ...other
}) => (
  <MyButton onClick={onClick} variant={variant} disabled={disabled} {...other}>
    {children}
  </MyButton>
);
JimButton.propTypes = {
  variant: oneOf(["primary", "secondary", "confirm", "cancel", "dismiss"])
    .isRequired,
  onClick: func,
  children: string,
  disabled: bool,
};
JimButton.defaultProps = {
  onClick: () => {},
  children: "",
  disabled: false,
};

const TimeLeftWrapper = styled.div(({ status }) => [
  tw`flex items-center text-sm italic`,
  status === 1 && tw`text-blue-600`,
  status === 2 && tw`text-yellow-600`,
  status === 3 && tw`text-yellow-500 font-semibold`,
  status === 4 && tw`text-red-600 font-semibold`,
]);

export const TimeLeftDiv = ({ start, ...props }) => {
  const startDate = new Date(parseInt(start, 10));
  const getVars = () => {
    const timeLeft = differenceInMinutes(startDate, new Date());
    let currentString = `in ${timeLeft} Minuten`;
    let currentStatus = 4;
    if (timeLeft > 21600) {
      currentString = `in ${Math.floor(timeLeft / 10080)} Wo., ${Math.round(
        (timeLeft % 10080) / 1440
      )} Tagen`;
      currentStatus = 0;
    } else if (timeLeft > 11520) {
      currentString = `in 1 Wo., ${Math.round(
        (timeLeft % 10080) / 1440
      )} Tagen`;
      currentStatus = 0;
    } else if (timeLeft > 4320) {
      currentString = `in ${Math.round(timeLeft / 1440)} Tagen`;
      currentStatus = 1;
    } else if (timeLeft > 1440) {
      currentString = `in ${Math.floor(timeLeft / 1440)} Tagen, ${Math.round(
        (timeLeft % 1440) / 60
      )} Std.`;
      currentStatus = 2;
    } else if (timeLeft > 360) {
      currentString = `in ${Math.round(timeLeft / 60)} Std.`;
      currentStatus = 3;
    } else if (timeLeft > 90) {
      currentString = `in ${Math.floor(timeLeft / 60)} Std., ${
        timeLeft % 60
      } Min.`;
      currentStatus = 3;
    }
    return { status: currentStatus, string: currentString };
  };

  const [status, setStatus] = useState(getVars().status);
  const [timeLeftString, setTimeLeftString] = useState(getVars().string);

  setInterval(() => {
    setStatus(getVars().status);
    setTimeLeftString(getVars().string);
  }, 60000);

  return (
    <TimeLeftWrapper status={status} {...props}>
      {timeLeftString}
    </TimeLeftWrapper>
  );
};
TimeLeftDiv.propTypes = {
  start: number.isRequired,
};

export const MyModal = styled(Modal)(() => [
  tw`flex items-center justify-center w-full h-full px-4 print:hidden`,
  `${mq.sm} {
    padding-left: calc(${drawerWidthSm}px + 16px);
  }
  ${mq.lg} {
    padding-left: calc(${drawerWidthLg}px + 16px);
  }`,
]);
const DialogWrapper = styled.div(({ bigger }) => [
  tw`flex max-w-full h-auto max-h-full focus:outline-none`,
  bigger && tw`md:w-224 xl:w-256`,
]);

export const JimDialog = ({
  open,
  handleClose,
  children,
  closable,
  title,
  bigger,
  ...other
}) => {
  const onClose = e => {
    e.preventDefault();
    e.stopPropagation();
    if (handleClose) {
      handleClose();
    }
  };
  return (
    <MyModal open={open} onClose={onClose}>
      <DialogWrapper bigger={bigger}>
        <TilePrim
          title={title}
          btnHandler={closable ? () => handleClose() : undefined}
          btnLabel={closable ? "abbr." : undefined}
          tw="my-0 md:max-w-4xl xl:max-w-5xl"
          {...other}
        >
          {children}
        </TilePrim>
      </DialogWrapper>
    </MyModal>
  );
};

JimDialog.propTypes = {
  open: bool.isRequired,
  handleClose: func,
  children: node,
  closable: bool,
  title: string,
  bigger: bool,
};
JimDialog.defaultProps = {
  handleClose: () => {},
  children: <div />,
  closable: false,
  title: "",
  bigger: false,
};

export const B = tw.div`font-semibold`;

export const TextButton = styled.div(({ disabled }) => [
  tw`flex items-center text-prim-500 font-semibold p-1 px-2 rounded-full cursor-pointer hover:(text-prim-700 bg-gray-500 bg-opacity-10) transition duration-200 select-none`,
  disabled &&
    tw`text-gray-500 cursor-default hover:(text-gray-500 bg-transparent)`,
]);

const EIWrapper = tw.div`text-gray-500 p-1 rounded-full cursor-pointer hover:(text-black bg-gray-200) transition duration-200`;

export const EditIconWrapper = ({ children, ...props }) => (
  <EIWrapper {...props}>{children}</EIWrapper>
);
EditIconWrapper.propTypes = {
  children: node.isRequired,
};

const TilePrimWrapper = styled.div(({ clickable }) => [
  tw`flex flex-col my-4 p-4 space-y-1 bg-white rounded-md shadow-md text-base overflow-y-auto print:(shadow-none rounded-none)`,
  // `${mq.sm} {
  //   max-height: calc(100vh - 10rem);
  // }
  // ${mq.xl} {
  //   max-height: calc(100vh - 12rem);
  // }`,
  clickable && tw`cursor-pointer hover:shadow-lg transition duration-200`,
]);

export const TilePrim = ({
  title,
  btnLabel,
  btnHandler,
  clickable,
  children,
  ...other
}) => (
  <TilePrimWrapper clickable={clickable} {...other}>
    {(title || btnHandler) && (
      <div tw="flex items-center justify-between">
        {title ? (
          <div tw="text-lg text-prim-500 tracking-wider mr-8">{title}</div>
        ) : (
          <div />
        )}
        {btnHandler && <TextButton onClick={btnHandler}>{btnLabel}</TextButton>}
      </div>
    )}
    {children}
  </TilePrimWrapper>
);
TilePrim.propTypes = {
  title: oneOfType([string, node]),
  btnLabel: oneOfType([string, node]),
  btnHandler: func,
  clickable: bool,
  children: node,
};
TilePrim.defaultProps = {
  title: undefined,
  btnLabel: undefined,
  btnHandler: undefined,
  clickable: false,
  children: <div />,
};

export const MyContainer = tw.div`w-full md:max-w-4xl xl:max-w-5xl mx-auto`;

export const UtilBar = tw.div`flex justify-between items-center text-gray-500 bg-white shadow-sm rounded-full px-1`;

export const SupportTile = ({ text }) => (
  <TilePrim tw="items-center">
    <div>{text}</div>
    <div tw="flex space-x-4">
      <TextButton>
        <a tw="flex items-center" href="mailto:support@morrowmed.de">
          <EmailRounded tw="text-xl mr-2" />
          support@morrowmed.de
        </a>
      </TextButton>
    </div>
  </TilePrim>
);
SupportTile.propTypes = {
  text: string.isRequired,
};

export const NotAllowedTile = () => (
  <SupportTile text="Für diese Funktion fehlen dir die nötigen Berechtigungen. Bitte wende dich an unseren Support." />
);

export const ErrorDialog = ({ errorMsg }) => {
  const [open, setOpen] = useState(true);

  return (
    <JimDialog open={open} handleClose={() => setOpen(false)}>
      <div>
        {errorMsg ||
          "Leider gab es einen unerwarteten Fehler. Bitte kontaktiere unseren Support"}
      </div>
      <div tw="flex space-x-4">
        <TextButton>
          <a tw="flex items-center" href="mailto:support@morrowmed.de">
            <EmailRounded tw="text-xl mr-2" />
            support@morrowmed.de
          </a>
        </TextButton>
      </div>
    </JimDialog>
  );
};
ErrorDialog.propTypes = {
  errorMsg: string,
};
ErrorDialog.defaultProps = {
  errorMsg: undefined,
};

export const LoadingTile = () => (
  <TilePrim tw="my-0">
    <Skeleton width={80} />
    <Skeleton width={140} />
  </TilePrim>
);

export const UtilsBarWrapper = tw.div`sticky z-50 flex flex-col space-y-2 sm:(flex-row space-y-0 space-x-4 items-center) w-full top-0 p-2 bg-gray-100`;

export const ContentWithUtilsBar = styled.div(() => [
  tw`overflow-y-auto p-2`,
  `${mq.sm} {
    max-height: calc(100vh - 7.5rem - 6rem);
  }
  ${mq.xl} {
    max-height: calc(100vh - 7.5rem - 8rem);
  }
`,
]);

export const ContentWithoutUtilsBar = styled.div(() => [
  tw`overflow-y-auto p-2`,
  `${mq.sm} {
    max-height: calc(100vh - 6rem);
  }
  ${mq.xl} {
    max-height: calc(100vh - 8rem);
  }
`,
]);

export const LoadingDialog = ({ open }) => (
  <JimDialog open={open}>
    <CircularProgress />
  </JimDialog>
);
LoadingDialog.propTypes = {
  open: bool,
};
LoadingDialog.defaultProps = {
  open: false,
};

export const JimLink = styled(Link)(({ disabled }) => [
  tw`flex items-center text-prim-500 font-semibold p-1 px-2 rounded-full cursor-pointer hover:(text-prim-700 bg-gray-500 bg-opacity-10) transition duration-200`,
  disabled &&
    tw`text-gray-500 cursor-default hover:(text-gray-500 bg-transparent)`,
]);
